import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = request.headers.append('Origin', environment.frontendUrl);
    if (request.url.startsWith('https://www.bungie.net/') && !request.url.startsWith('https://www.bungie.net/common')) {
      const cloneReq = request.clone({
        headers: headers.set('x-api-key', environment.apiConfig.apiKey),
      });
      return next.handle(cloneReq);
    }
    return next.handle(request);
  }
}
