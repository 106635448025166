<div class="privacy">
  <h1>Privacy Policy</h1>
  <div class="content">
    <label>This Privacy Policy governs the manner in which Destiny Recipes. collects, uses, maintains and discloses information collected from users (each, a "User") of the destinyrecipes.com website ("Site"). This privacy policy applies to the Site and all products and services offered by Destiny Recipes.</label>
    <div>
      <h2>Analytics Data</h2>
      <label>We may collect non-personal identification information about how users interact with our Site. Such informations may include the type of browser, the means of connection to our Site and other technical informations about the pages and links Users interact with. Those data are shared to our Analytics third party service : Google Analytics.
      </label>
    </div>
    <div>
      <h2>Advertising</h2>
      <label>Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers. In addition, NAI member companies serve cookies via this website as part of the opt-out process. To enable a consumer to opt out of IBA by a member company, the member company must set an "opt-out cookie" on the consumer's browser. Please note that if you delete any opt-out cookies obtained using the NAI opt-out page, such as by clearing all cookies in your browser, you will need to return to the opt-out page to renew your choices. However, the DAA offers a tool that helps to preserve your opt-out cookies and prevent them from being deleted.
      </label>
    </div>
    <div>
      <h2>Third party websites</h2>
      <label>Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.
      </label>
    </div>
    <div>
      <h2>Advertising Privacy Settings</h2>
      <label>FOR EU USERS ONLY: When you use our site, pre-selected companies may access and use certain information on your device and about your interests to serve ads or personalized content. You may revisit or change consent-choices at any time by using the button below.<div id="ncmp-consent-link"></div>
      </label>
    </div>
    <div>
      <h2>For California Residents: CCPA Information</h2>
      <h3>Rights and Choices</h3>
      <label>The CCPA provides consumers located in the state of California with certain rights regarding their personal information and data. The following section describes those rights and explains how to exercise them:</label>

      <h3>Access to Specific Information and Data Portability Rights</h3>
      <label>You have the right to request that Destiny Recipes disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (as described in the section “Exercising Access, Data Portability, and Deletion Rights”), we will disclose to you:</label>

      <ul><li>The categories of personal information we collected about you.</li>
        <li>The categories of sources for the personal information we collected about you.</li>
        <li>Our business or commercial purpose for collecting or selling that personal information.</li>
        <li>The categories of third parties with whom we share that personal information.</li>
        <li>The specific pieces of personal information we collected about you (also called data portability request)</li>
        <li>If we sold or disclose your personal information for a business purpose, two separate lists disclosing:</li>
        <li>Sales, identifying the personal information categories that each category of recipient purchased, and</li>
        <li>Disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained</li></ul>
      <h3>Non-Discrimination</h3>

      <label>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</label>

      <ul>
        <li>Deny you goods or services.</li>
        <li>Charge you different prices or rates for goods or services, including through granting discounts or imposing penalties.</li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>Suggest that you may receive a different price or rate for goods or services or a different level of quality of goods or services.</li>
        <li>Any CCPA-permitted financial incentive we offer will reasonably relate to your value and contain written terms that describe the program’s material aspects</li></ul>

      <h3>Exercising Access, Data Portability, and Deletion Rights</h3>
      <label>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by emailing us at destiny&commat;method.gg</label>

      <label>Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</label>

      <label>You may only make a verifiable consumer request for access of data portability twice within a 12-month period. The verifiable consumer request must:</label>

      <ul><li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.
        Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
        <li>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.</li></ul>

      <label>Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</label>
    </div>
    <div>
      <h2>Changes to this privacy policy</h2>
      <label>Destiny Recipes has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
      </label>
    </div>
    <div>
      <h2>Your acceptance of these terms</h2>
      <label>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
      </label>
    </div><br>
    <label>This document was last updated on September 22, 2024</label>
  </div>
</div>
